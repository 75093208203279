import React, { Fragment } from 'react';
import styled, { withTheme } from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { object, shape } from 'prop-types';

import { breakpoint } from 'ui/settings';
import { Fold, Helmet, Layout } from 'ui/partials';
import { setSpace, setType } from 'ui/mixins';
import { Action, Actionbar, Copy, Separator, TextBlock, TextString, Title } from 'ui/components';

const Project = styled.article`
  ${setType('x')};
`;

const Summary = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  ${breakpoint.phone} {
    text-align: center;
  }
`;

const Definitions = styled.div`
  ${setSpace('pvl')};
  border-bottom: 1px solid ${({ theme }) => theme.decor};
  border-top: 1px solid ${({ theme }) => theme.decor};
  margin-left: auto;
  margin-right: auto;
  max-width: 580px;
  & > div:not(:first-child) {
    ${setSpace('mts')};
    ${setSpace('prl')};
  }
  span {
    display: block;
  }
`;

const ProjectTpl = props => {
  const { theme } = props;
  const { html, frontmatter } = props.data.markdownRemark;
  const {
    contribution,
    description,
    keywords,
    launcherLabel,
    launcherUrl,
    period,
    skills,
    summary,
    title,
    cover,
    type,
  } = frontmatter;
  return (
    <Fragment>
      <Helmet {...props} title={`${frontmatter.title} ⋅ Portfolio`} description={description} keywords={keywords} />
      <Layout {...props}>
        <Fold>
          <TextBlock>
            <Title>
              <TextString looks="hcap">From the portfolio</TextString>
              <TextString looks="h1">{frontmatter.title}</TextString>
            </Title>
          </TextBlock>
        </Fold>
        <Separator ornamental size="h" />
        <Project>
          <Summary>
            <Title looks="h4">{summary}</Title>
          </Summary>
          <Separator ornamental size="h" />
          <GatsbyImage alt={title} image={getImage(cover)} />
          <Separator ornamental size="h" />
          <Copy dangerouslySetInnerHTML={{ __html: html }} />
          <Separator silent size="l" />
          <Definitions theme={theme}>
            <div>
              <TextString looks="label">Product type:</TextString>
              <TextString looks="p6">{type}</TextString>
            </div>
            <div>
              <TextString looks="label">Contribution:</TextString>
              <TextString looks="p6">{contribution}</TextString>
            </div>
            <div>
              <TextString looks="label">Skills exercised:</TextString>
              <TextString looks="p6">{skills}</TextString>
            </div>
            <div>
              <TextString looks="label">Engagement period:</TextString>
              <TextString looks="p6">{period}</TextString>
            </div>
          </Definitions>
          <Separator silent size="l" />
          <Actionbar center>
            <Action button href={launcherUrl}>
              {launcherLabel}
            </Action>
          </Actionbar>
        </Project>
      </Layout>
    </Fragment>
  );
};

export default withTheme(ProjectTpl);

ProjectTpl.propTypes = {
  data: shape({
    markdownRemark: object.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query ProjectByUID($uid: String!) {
    markdownRemark(frontmatter: { uid: { eq: $uid } }) {
      html
      frontmatter {
        contribution
        description
        keywords
        launcherLabel
        launcherUrl
        period
        skills
        summary
        title
        type
        uid
        cover {
          childImageSharp {
            gatsbyImageData(quality: 95)
          }
        }
      }
    }
  }
`;
